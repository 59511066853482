import UObject from "./UObject.mts";

namespace Enum {
  export type Any = Record<string | number, string | number>;
  export const keyOf = <
    Enum extends Enum.Any,
    Value extends UObject.Value<Enum>,
  >(
    enm: Enum,
    value: UObject.Value<Enum>,
  ) => enm[value] as unknown as UObject.Key.ForValue<Enum, Value>;
}

export default Enum;
