// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/services/asgard/auth_service.proto (package kikoff_canada.protobuf.services.asgard, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AuthGoogleSsoCallbackRequest, AuthGoogleSsoCallbackResponse, AuthShowCurrentAdminResponse } from "./auth_service_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service kikoff_canada.protobuf.services.asgard.AuthService
 */
export const AuthService = {
  typeName: "kikoff_canada.protobuf.services.asgard.AuthService",
  methods: {
    /**
     * @generated from rpc kikoff_canada.protobuf.services.asgard.AuthService.GoogleSsoCallback
     */
    googleSsoCallback: {
      name: "GoogleSsoCallback",
      I: AuthGoogleSsoCallbackRequest,
      O: AuthGoogleSsoCallbackResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc kikoff_canada.protobuf.services.asgard.AuthService.ShowCurrentAdmin
     */
    showCurrentAdmin: {
      name: "ShowCurrentAdmin",
      I: Empty,
      O: AuthShowCurrentAdminResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

