import { createPromiseClient } from "@connectrpc/connect";

import UFunction from "@repo/utils/UFunction";

import { AuthService } from "../../gen/kikoff_canada/protobuf/services/asgard/auth_service_connect";
import Rpc from "../utils/Rpc";

import { canadaAdminServices } from "./services";

const stubs = import.meta.env.DEV
  ? UFunction.memo(() =>
      import("../canada/admin/stubs").then((res) => res.default),
    )
  : undefined;

const serviceCache: Rpc.Cache = {};

const transport = Rpc.createTransport({
  namespace: "kikoff_canada.protobuf.services.asgard.",
  serviceCache,
  basePath: "/asgard",
});

serviceCache.AuthService = {
  spec: AuthService,
  client: createPromiseClient(AuthService, transport),
};

const canadaAdminRpc = Rpc.createServicesClient(canadaAdminServices, {
  serviceCache,
  transport,
  stubs,
});

export default canadaAdminRpc;
