import assert from "@repo/utils/assert";
import Env from "@repo/utils-client/Env";

namespace Api {
  assert(
    import.meta.env.API_ORIGIN,
    'Required environment variable "API_ORIGIN" not present.',
  );
  export const origin = Env.resolveUrl(import.meta.env.API_ORIGIN);
}

export default Api;
