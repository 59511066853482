// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/types/error.proto (package kikoff_canada.protobuf.types, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message kikoff_canada.protobuf.types.Error
 */
export class Error extends Message<Error> {
  /**
   * @generated from field: kikoff_canada.protobuf.types.Error.Code code = 1;
   */
  code = Error.Code.UNKNOWN;

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  /**
   * @generated from field: string field = 3;
   */
  field = "";

  constructor(data?: PartialMessage<Error>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.types.Error";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "enum", T: proto3.getEnumType(Error.Code) },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Error {
    return new Error().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Error {
    return new Error().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Error {
    return new Error().fromJsonString(jsonString, options);
  }

  static equals(a: Error | PlainMessage<Error> | undefined, b: Error | PlainMessage<Error> | undefined): boolean {
    return proto3.util.equals(Error, a, b);
  }

  static _enums: {
    Code: typeof Error.Code,
  };
}

export namespace Error {
  /**
   * In an effort to prevent this enum from exploding with variants, only add
   * error codes if we expect special behavior on the client. 
   * Note:
   * - Errors can be field specific, an error code should not specify which
   *    field it applies to
   * - Errors will always be used in the context of a response, an error code
   *    should not specify which response it applies to
   * - Error codes are meant to replace custom response statuses, only add them
   *    if you would've added a custom status
   *
   * @generated from enum kikoff_canada.protobuf.types.Error.Code
   */
  export enum Code {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: BAD_REQUEST = 1;
     */
    BAD_REQUEST = 1,

    /**
     * @generated from enum value: NEEDS_REFRESH = 2;
     */
    NEEDS_REFRESH = 2,

    /**
     * @generated from enum value: CONTACT_POINT_TAKEN = 3;
     */
    CONTACT_POINT_TAKEN = 3,

    /**
     * @generated from enum value: INVALID_CONFIRMATION_CODE = 4;
     */
    INVALID_CONFIRMATION_CODE = 4,
  }
  // Retrieve enum metadata with: proto3.getEnumType(Error.Code)
  proto3.util.setEnumType(Error.Code, "kikoff_canada.protobuf.types.Error.Code", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "BAD_REQUEST" },
    { no: 2, name: "NEEDS_REFRESH" },
    { no: 3, name: "CONTACT_POINT_TAKEN" },
    { no: 4, name: "INVALID_CONFIRMATION_CODE" },
  ]);
}

