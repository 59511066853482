// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/services/asgard/auth_service.proto (package kikoff_canada.protobuf.services.asgard, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Error } from "../../types/error_pb.js";
import { Admin } from "../../models/admin_pb.js";

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.AuthGoogleSsoCallbackRequest
 */
export class AuthGoogleSsoCallbackRequest extends Message<AuthGoogleSsoCallbackRequest> {
  /**
   * @generated from field: string credential = 1;
   */
  credential = "";

  constructor(data?: PartialMessage<AuthGoogleSsoCallbackRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.AuthGoogleSsoCallbackRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credential", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthGoogleSsoCallbackRequest {
    return new AuthGoogleSsoCallbackRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthGoogleSsoCallbackRequest {
    return new AuthGoogleSsoCallbackRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthGoogleSsoCallbackRequest {
    return new AuthGoogleSsoCallbackRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AuthGoogleSsoCallbackRequest | PlainMessage<AuthGoogleSsoCallbackRequest> | undefined, b: AuthGoogleSsoCallbackRequest | PlainMessage<AuthGoogleSsoCallbackRequest> | undefined): boolean {
    return proto3.util.equals(AuthGoogleSsoCallbackRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.AuthGoogleSsoCallbackResponse
 */
export class AuthGoogleSsoCallbackResponse extends Message<AuthGoogleSsoCallbackResponse> {
  /**
   * @generated from field: repeated kikoff_canada.protobuf.types.Error errors = 1;
   */
  errors: Error[] = [];

  /**
   * @generated from field: kikoff_canada.protobuf.models.Admin admin = 2;
   */
  admin?: Admin;

  constructor(data?: PartialMessage<AuthGoogleSsoCallbackResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.AuthGoogleSsoCallbackResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: Error, repeated: true },
    { no: 2, name: "admin", kind: "message", T: Admin },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthGoogleSsoCallbackResponse {
    return new AuthGoogleSsoCallbackResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthGoogleSsoCallbackResponse {
    return new AuthGoogleSsoCallbackResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthGoogleSsoCallbackResponse {
    return new AuthGoogleSsoCallbackResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AuthGoogleSsoCallbackResponse | PlainMessage<AuthGoogleSsoCallbackResponse> | undefined, b: AuthGoogleSsoCallbackResponse | PlainMessage<AuthGoogleSsoCallbackResponse> | undefined): boolean {
    return proto3.util.equals(AuthGoogleSsoCallbackResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.AuthShowCurrentAdminResponse
 */
export class AuthShowCurrentAdminResponse extends Message<AuthShowCurrentAdminResponse> {
  /**
   * @generated from field: repeated kikoff_canada.protobuf.types.Error errors = 1;
   */
  errors: Error[] = [];

  /**
   * @generated from field: kikoff_canada.protobuf.models.Admin admin = 2;
   */
  admin?: Admin;

  constructor(data?: PartialMessage<AuthShowCurrentAdminResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.AuthShowCurrentAdminResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: Error, repeated: true },
    { no: 2, name: "admin", kind: "message", T: Admin },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthShowCurrentAdminResponse {
    return new AuthShowCurrentAdminResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthShowCurrentAdminResponse {
    return new AuthShowCurrentAdminResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthShowCurrentAdminResponse {
    return new AuthShowCurrentAdminResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AuthShowCurrentAdminResponse | PlainMessage<AuthShowCurrentAdminResponse> | undefined, b: AuthShowCurrentAdminResponse | PlainMessage<AuthShowCurrentAdminResponse> | undefined): boolean {
    return proto3.util.equals(AuthShowCurrentAdminResponse, a, b);
  }

  static _enums: {
  };
}

