import UString from "./UString.mts";
import UType from "./UType.mts";

namespace Case {
  export type Type = "camel" | "kebab" | "snake" | "sentance";

  type NextUppercaseWord<S extends string> =
    S extends `${infer C0}${infer C1}${infer R}`
      ? `${C0}${C1}` extends Uppercase<`${C0}${C1}`>
        ? `${C0}${NextUppercaseWord<`${C1}${R}`>}`
        : ""
      : S extends `${infer C}${string}`
        ? C extends Uppercase<C>
          ? C
          : ""
        : "";

  type NextLowercaseGroup<S extends string> = S extends `${infer C0}${infer R}`
    ? C0 extends Lowercase<C0>
      ? `${C0}${NextLowercaseGroup<R>}`
      : ""
    : "";

  export type FromCamel<S extends string> = string extends S
    ? [string]
    : NextUppercaseWord<S> extends infer UppercaseWord extends string
      ? UppercaseWord extends ""
        ? S extends `${infer C0}${infer R}`
          ? NextLowercaseGroup<R> extends infer LowercaseGroup extends string
            ? [
                `${Lowercase<C0>}${LowercaseGroup}`,
                ...FromCamel<UString.AfterPrefix<R, LowercaseGroup>>,
              ]
            : never
          : []
        : [
            Lowercase<UppercaseWord>,
            ...FromCamel<UString.AfterPrefix<S, UppercaseWord>>,
          ]
      : never;

  export type FromPascal<S extends string> = FromCamel<S>;

  type NextSeparatorWord<
    S extends string,
    Separator extends string,
  > = S extends `${infer C0}${infer R}`
    ? C0 extends Separator
      ? ""
      : `${C0}${NextSeparatorWord<R, Separator>}`
    : "";

  type FromSeparated<
    S extends string,
    Separator extends string,
  > = string extends S
    ? [string]
    : NextSeparatorWord<S, Separator> extends infer Word extends string
      ? S extends `${Word}${infer R}`
        ? [
            Word,
            ...(R extends ""
              ? []
              : FromSeparated<UString.AfterPrefix<R, Separator>, Separator>),
          ]
        : never
      : never;
  export type FromKebab<S extends string> = S extends S
    ? FromSeparated<S, "-">
    : never;
  export type FromSnake<S extends string> = S extends S
    ? FromSeparated<S, "_">
    : never;
  export type FromConstant<S extends string> = FromSnake<Lowercase<S>>;
  export type FromSentance<S extends string> = S extends S
    ? FromSeparated<Lowercase<S>, " ">
    : never;

  export type ToPascal<Words extends string[]> = Words extends [
    infer Word extends string,
    ...infer Rest extends string[],
  ]
    ? `${UString.Capitalize<Word>}${ToPascal<Rest>}`
    : "";
  export type ToCamel<Words extends string[]> = UString.Decapitalize<
    ToPascal<Words>
  >;
  type ToSeparated<
    Words extends string[],
    Separator extends string,
  > = Words extends [infer Word extends string, ...infer Rest extends string[]]
    ? `${Word}${ToSeparated<Rest, Separator> extends infer Separated extends
        string
        ? Separated extends ""
          ? ""
          : `${Separator}${Separated}`
        : never}`
    : "";
  export type ToKebab<Words extends string[]> = ToSeparated<Words, "-">;
  export type ToSnake<Words extends string[]> = ToSeparated<Words, "_">;
  export type ToConstant<Words extends string[]> = Uppercase<ToSnake<Words>>;
  export type ToSentance<Words extends string[]> = UString.Capitalize<
    ToSeparated<Words, " ">
  >;
}

const splitCamelRegex = /[A-Z0-9]+[a-z]?/g;

class Case<Words extends string[]> {
  static fromCamel<S extends string>(string: S) {
    return new Case<UType.Narrow<Case.FromCamel<S>, string[]>>("camel", string);
  }
  static fromPascal<S extends string>(string: S) {
    return this.fromCamel(string);
  }
  static fromKebab<S extends string>(string: S) {
    return new Case<Case.FromKebab<S>>("kebab", string);
  }
  static fromSnake<S extends string>(string: S) {
    return new Case<Case.FromSnake<S>>("snake", string);
  }
  static fromConstant<S extends string>(string: S) {
    return new Case<Case.FromConstant<S>>("snake", string.toLowerCase());
  }
  static fromSentance<S extends string>(string: S) {
    return new Case<Case.FromSentance<S>>("sentance", string.toLowerCase());
  }

  private constructor(
    private sourceCase: Case.Type,
    private string: string,
  ) {}

  private static splitByType: Record<Case.Type, (string: string) => string[]> =
    {
      camel: (string: string) =>
        string.length === 1
          ? [string.toLowerCase()]
          : string
              .replace(splitCamelRegex, (string, offset) =>
                `${offset > 0 ? "-" : ""}${
                  string.length <= 2
                    ? string
                    : // Uppercase word
                      `${string.slice(0, -2)}${
                        /[a-z]/.test(string.at(-1) || "") ? "-" : ""
                      }${string.slice(-2)}`
                }`.toLowerCase(),
              )
              .split("-"),
      kebab: (string: string) => string.split("-"),
      snake: (string: string) => string.split("_"),
      sentance: (string: string) => string.split(" "),
    };

  private get words(): Words {
    Object.defineProperty(this, "words", {
      value: Case.splitByType[this.sourceCase](this.string),
      writable: false,
    });
    return this.words;
  }

  toPascal() {
    return `${this.words.map(UString.capitalize).join("")}` as Case.ToPascal<Words>;
  }
  toCamel() {
    return UString.decapitalize(this.toPascal()) as Case.ToCamel<Words>;
  }
  toKebab() {
    return this.words.join("-") as Case.ToKebab<Words>;
  }
  toSnake() {
    return this.words.join("_") as Case.ToSnake<Words>;
  }
  toConstant() {
    return this.toSnake().toUpperCase() as Case.ToSnake<Words>;
  }
  toSentance() {
    return UString.capitalize(this.words.join(" ")) as Case.ToSentance<Words>;
  }
}

export default Case;
