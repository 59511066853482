namespace UPromise {
  export type Maybe<T> = T | PromiseLike<T>;
  export type Awaitable<T> = T | PromiseLike<Awaitable<T>>;

  export const delay = (ms: number) =>
    new Promise<void>((resolve) => setTimeout(resolve, ms));

  export namespace PendingCache {
    export function create<Key = unknown, Value = unknown>() {
      const cache = new Map<Key, Promise<Value>>();

      return {
        use(
          key: Key,
          create: () => Promise<Value>,
        ): Promise<Value> & { fromCache?: boolean } {
          if (cache.has(key))
            return Object.assign(Promise.resolve(cache.get(key)!), {
              fromCache: true,
            });

          const promise = create();

          cache.set(key, promise);
          promise.finally(() => {
            cache.delete(key);
          });

          return promise;
        },
      };
    }
  }
}

export default UPromise;
