// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/models/admin.proto (package kikoff_canada.protobuf.models, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message kikoff_canada.protobuf.models.Admin
 */
export class Admin extends Message<Admin> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: kikoff_canada.protobuf.models.Admin.Role role = 3;
   */
  role = Admin.Role.NONE;

  /**
   * @generated from field: kikoff_canada.protobuf.models.Admin.Status status = 4;
   */
  status = Admin.Status.UNKNOWN;

  constructor(data?: PartialMessage<Admin>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.models.Admin";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(Admin.Role) },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(Admin.Status) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Admin {
    return new Admin().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Admin {
    return new Admin().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Admin {
    return new Admin().fromJsonString(jsonString, options);
  }

  static equals(a: Admin | PlainMessage<Admin> | undefined, b: Admin | PlainMessage<Admin> | undefined): boolean {
    return proto3.util.equals(Admin, a, b);
  }

  static _enums: {
    Role: typeof Admin.Role,
    Status: typeof Admin.Status,
  };
}

export namespace Admin {
  /**
   * @generated from enum kikoff_canada.protobuf.models.Admin.Role
   */
  export enum Role {
    /**
     * @generated from enum value: NONE = 0;
     */
    NONE = 0,

    /**
     * @generated from enum value: SYSADMIN = 1;
     */
    SYSADMIN = 1,

    /**
     * @generated from enum value: DEVELOPER = 2;
     */
    DEVELOPER = 2,

    /**
     * @generated from enum value: CUSTOMER_SUPPORT = 3;
     */
    CUSTOMER_SUPPORT = 3,
  }
  // Retrieve enum metadata with: proto3.getEnumType(Admin.Role)
  proto3.util.setEnumType(Admin.Role, "kikoff_canada.protobuf.models.Admin.Role", [
    { no: 0, name: "NONE" },
    { no: 1, name: "SYSADMIN" },
    { no: 2, name: "DEVELOPER" },
    { no: 3, name: "CUSTOMER_SUPPORT" },
  ]);
}

export namespace Admin {
  /**
   * @generated from enum kikoff_canada.protobuf.models.Admin.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: ACTIVE = 1;
     */
    ACTIVE = 1,

    /**
     * @generated from enum value: DISABLED = 2;
     */
    DISABLED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(Admin.Status)
  proto3.util.setEnumType(Admin.Status, "kikoff_canada.protobuf.models.Admin.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "ACTIVE" },
    { no: 2, name: "DISABLED" },
  ]);
}

